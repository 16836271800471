import { render, staticRenderFns } from "./checkDataquality.vue?vue&type=template&id=53d5f640&scoped=true&"
import script from "./checkDataquality.vue?vue&type=script&lang=js&"
export * from "./checkDataquality.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d5f640",
  null
  
)

export default component.exports