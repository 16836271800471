<template>
    <!-- 查看系统的对话框 -->
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dataQuality' }">数据清洗标准</el-breadcrumb-item>
            <el-breadcrumb-item >查看</el-breadcrumb-item>
        </el-breadcrumb>
        <span style="font-size: 16px;">搜索：</span>
        <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <el-card>
            <el-table :data="tableData" style="width: 100%" border stripe>
                <el-table-column type="index" label="#" header-align="center" align="center" width="60">
                </el-table-column>
                <el-table-column prop="device_name" label="设备名称" header-align="center" align="center" width="150">
                </el-table-column>
                <el-table-column prop="describe" label="描述" header-align="center" align="center" width="500">
                </el-table-column>
                <el-table-column label="操作" header-align="center" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="coalwatch(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" header-align="center" align="center" width="150">
                </el-table-column>

            </el-table>

            <!-- 分页区域 -->
            <!--<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[1, 2, 5, 10]" :page-size="1" layout="total, sizes, prev, pager, next, jumper" :total="5">
            </el-pagination>-->
            <div style="display: flex;justify-content: flex-end">
                <el-pagination
                        background
                        @current-change="currentChange"
                        @size-change="sizeChange"
                        :current-page="page"
                        :page-size="size"
                        layout="sizes, prev, pager, next, jumper, ->, total, slot"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        name: "checkSystem",
        mounted() {
            var coalId = window.sessionStorage.getItem("dataQualitySystemId");
            this.currentSystemId = parseInt(coalId);
        },
        data() {
            return {
                currentSystemId: 1,
                visible: false,
                options: [{
                    value: '选项1',
                    label: '煤中央皮带'
                }, {
                    value: '选项2',
                    label: '主平硐皮带'
                }, {
                    value: '选项3',
                    label: '上仓皮带'
                }],
                value: '',
                tableData: [{
                    device_name: '皮带本体',
                    describe: 'YB2-5002-4型，功率900kW，U=6kV,I=105.1A，功率因数0.86',
                    remark: '暂无'
                }, {
                    device_name: '头部1#驱动电机',
                    describe: 'YB2-5002-4型，功率900kW，U=6kV,I=105.1A，功率因数0.86',
                    remark: '暂无'
                }, {
                    device_name: '头部2#驱动电机',
                    describe: 'YB2-5002-4型，功率900kW，U=6kV,I=105.1A，功率因数0.86',
                    remark: '暂无'
                }, {
                    device_name: '头部3#驱动电机',
                    describe: 'YB2-5002-4型，功率900kW，U=6kV,I=105.1A，功率因数0.86',
                    remark: '暂无'
                }, {
                    device_name: '头部4#驱动电机',
                    describe: 'YB2-5002-4型，功率900kW，U=6kV,I=105.1A，功率因数0.86',
                    remark: '暂无'
                }],
                page:1,  //当前第几页
                size:10, //当前每页个数
                total:100 //全部数据行数
            }
        },
        methods: {
            // 监听 pagesize 改变的事件
            currentChange(currentPage){
                this.page=currentPage;
                this.initData();
            },
            sizeChange(currentSize) {
                this.size = currentSize;
                this.initData();
            },
            coalwatch(id) {
                this.$router.push({
                    path: '/checkDevice'
                })
            },
            initData(){
                //在这里初始化数据，三个条件，当前页数，每页数，当前子系统，可得到设备列表
            }

        }
    }
</script>


<style scoped>

</style>
